import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { IconButton } from '_common/component/TableColumnCustom';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { REGEX, SUMI_SHADOW } from '_common/constants/common';
import {
  messageValidate,
  messageValidateLength,
  messageWarning,
} from '_common/constants/message';
import Input from '_common/dof/Control/Input';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import { usePrompt } from '_common/hooks/usePrompt';
import { arraysAreEqual } from '_common/utils';
import classNames from 'classnames';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ArrowSmallLeftOutlined,
  BookmarkOutlined,
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  ItemType,
  notification,
  Row,
  Spin,
  Tabs,
} from 'tera-dls';
import PermissionApi from '../../api';
import { MODULE_TITLE, PAGE_TITLE } from '../../constants';
import { PERMISSION_URL } from '../../url';

const headingClassName =
  'text-blue-500 font-medium text-base border-b py-4 px-2.5';

function PermissionForm() {
  const form = useForm({
    mode: 'onChange',
  });
  const [listPermission, setListPermission] = useState<number[]>([]);
  const [activeKeyEpic, setActiveKeyEpic] = useState<string>(null);
  const [activeKeyModule, setActiveKeyModule] = useState<string>('home');
  const [dirty, setDirty] = useState<boolean>(false);

  const isDirty = form.formState.isDirty;

  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data } = useQuery(
    ['get-control-list', activeKeyModule],
    () => {
      const params = {
        epic_key: activeKeyModule,
      };
      return PermissionApi.getListControls({ params });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  const {
    data: dataDetails,
    isLoading,
    refetch,
  } = useQuery(
    ['get-permission-detail', id],
    () => PermissionApi.getDetail({ id }),
    {
      enabled: !!id,
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  const { mutate: mutateCategory, isLoading: isLoadingMutate } = useMutation(
    (params: any) => {
      if (id) return PermissionApi.update({ id, params });
      return PermissionApi.create({ params });
    },
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          form.reset();
          setDirty(false);
          queryClient.invalidateQueries(['get-permission-list']);
          setTimeout(() => navigate(-1));
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const disabled = isLoadingMutate || (isLoading && !!id);

  const permissions = useMemo(() => {
    if (!data) return undefined;
    const groupedPermission = _.groupBy(data, 'page_key');
    const converted = Object.entries(groupedPermission)?.reduce(
      (result, [key, values]) => ({
        ...result,
        [key]: {
          name: PAGE_TITLE[key],
          controls: values?.map((item) => ({
            id: item?.id,
            title: item?.function_title,
            key: item?.permission_key,
          })),
        },
      }),
      {},
    );
    return converted;
  }, [data]);

  const itemEpic = useMemo(() => {
    if (!permissions) return [];
    const items = Object.keys(permissions || {}).map((key) => ({
      key,
      label: PAGE_TITLE[key],
    }));
    return items;
  }, [permissions]);

  const handleSubmitForm = (values) => {
    const data = {
      ...values,
      roles: listPermission,
    };
    mutateCategory(data);
  };

  const BreadcrumbItem: ItemType[] = [
    {
      title: (
        <a
          onClick={() => navigate(PERMISSION_URL.list.path)}
          className="!text-blue-400 hover:!text-blue-600"
        >
          Danh sách quyền
        </a>
      ),
    },
    {
      title: id ? 'Sửa cấu hình quyền' : 'Thêm cấu hình quyền',
    },
  ];

  const handleChangeCheck = (e) => {
    if (e.target.checked) {
      setListPermission((prev) => [...prev, Number(e.target.value)]);
      return;
    }
    setListPermission((prev) => {
      const largeSet = new Set(prev);
      largeSet.delete(Number(e.target.value));
      return Array.from(largeSet);
    });
  };

  const handleChangeAllEpic = useCallback(
    (e) => {
      const listSetPermission = new Set(listPermission);
      permissions?.[activeKeyEpic]?.controls.forEach((item) => {
        if (e.target.checked) {
          if (!listSetPermission.has(item.id)) {
            listSetPermission.add(item.id);
          }
          return;
        }
        if (listSetPermission.has(item.id)) listSetPermission.delete(item.id);
      });
      setListPermission(Array.from(listSetPermission));
    },
    [activeKeyEpic, permissions, listPermission],
  );

  const handleChangeAllModule = useCallback(
    (e) => {
      const listSetPermission = new Set(listPermission);
      const listKey = Object.keys(permissions || {})?.reduce(
        (total, current) => {
          if (permissions)
            return [...total, ...(permissions?.[current]?.controls || [])];
          return [];
        },
        [],
      );
      listKey.forEach((item) => {
        if (e.target.checked) {
          if (!listSetPermission.has(item.id)) {
            listSetPermission.add(item.id);
          }
          return;
        }
        if (listSetPermission.has(item.id)) listSetPermission.delete(item.id);
      });
      setListPermission(Array.from(listSetPermission));
    },
    [permissions, listPermission],
  );

  const isCheckAllModule = useMemo(() => {
    const listSetPermission = new Set(listPermission);
    const listKey = Object.keys(permissions || {})?.reduce((total, current) => {
      if (permissions)
        return [...total, ...(permissions?.[current]?.controls || [])];
      return [];
    }, []);
    const allKeysExist = listKey?.every((item) =>
      listSetPermission.has(item.id),
    );
    return allKeysExist;
  }, [listPermission, permissions]);

  const isCheckAllEpic = useMemo(() => {
    const listSetPermission = new Set(listPermission);
    const allKeysExist = permissions?.[activeKeyEpic]?.controls?.every((item) =>
      listSetPermission.has(item.id),
    );
    return allKeysExist;
  }, [activeKeyEpic, listPermission, permissions]);

  useEffect(() => {
    setActiveKeyEpic(Object.keys(permissions || {})[0]);
  }, [permissions]);

  const itemModule = Object.entries(MODULE_TITLE).map(([key, label]) => ({
    key,
    label,
  }));

  useEffect(() => {
    if (dataDetails) {
      const role_has_permission = dataDetails?.role_has_permission?.map(
        (item) => item?.permission_id,
      );
      const data = {
        code: dataDetails?.code,
        name: dataDetails?.name,
      };
      setListPermission(role_has_permission);
      form.reset(data);
    }
  }, [dataDetails]);

  useEffect(() => {
    if (id) refetch();
  }, [id]);

  useEffect(() => {
    if (id) {
      const role_has_permission = dataDetails?.role_has_permission?.map(
        (item) => item?.permission_id,
      );
      const isEqual = arraysAreEqual(role_has_permission, listPermission);

      if (!isEqual || isDirty) setDirty(true);
      else setDirty(false);
    } else {
      if (isDirty || !!listPermission?.length) setDirty(true);
    }
  }, [dataDetails, listPermission, isDirty]);

  usePrompt(
    <>
      <p>{messageWarning.WARNING_EXIT_1}</p>
      <p>{messageWarning.WARNING_EXIT_2}</p>
    </>,
    dirty,
  );

  return (
    <div className="tera-page-form">
      <div className="page-header-sticky">
        <div className="page-header-v2">
          <div className="page-header-v2__breadcrumb">
            <IconButton
              icon={<ArrowSmallLeftOutlined />}
              callBack={() => navigate(-1)}
            />
            <Breadcrumb separator="/" items={BreadcrumbItem} />
          </div>
          <div className="page-header-v2__function">
            <Button
              className="page-header-v2-btn"
              type="success"
              prefix={<BookmarkOutlined />}
              onClick={form.handleSubmit(handleSubmitForm)}
              disabled={disabled}
            >
              Lưu
            </Button>
          </div>
        </div>
      </div>
      <Spin spinning={disabled}>
        <div className="page-content-v2">
          <FormTera
            form={form}
            className={classNames('p-4 rounded bg-white', SUMI_SHADOW)}
          >
            <Row className="grid-cols-2">
              <FormTeraItem
                label="Mã quyền"
                name="code"
                rules={[
                  {
                    required: messageValidate.emptyText,
                    pattern: {
                      value: new RegExp(REGEX.CODE),
                      message: messageValidate.code,
                    },
                    maxLength: {
                      value: 255,
                      message: messageValidateLength.textLong,
                    },
                  },
                ]}
              >
                <Input disabled={!!id} />
              </FormTeraItem>
              <FormTeraItem
                label="Tên quyền"
                name="name"
                rules={[
                  {
                    required: messageValidate.emptyText,
                    maxLength: {
                      value: 255,
                      message: messageValidateLength.textLong,
                    },
                  },
                ]}
              >
                <Input />
              </FormTeraItem>
              <Col className="col-span-2 rounded overflow-hidden border">
                <Row className="grid-cols-4 gap-0">
                  <Col className={classNames(headingClassName, 'col-span-1')}>
                    Danh sách module
                  </Col>
                  <Col
                    className={classNames(
                      headingClassName,
                      'col-span-3 border-l',
                    )}
                  >
                    Danh sách màn hình và chức năng
                  </Col>
                  <Col className="col-span-1 bg-[#F3F4F6]">
                    <Tabs
                      activeClassName="bg-white"
                      className="sumi-system-permission__tab-container border-r-0"
                      itemClassName="sumi-system-permission__tab-item border-b"
                      tabPosition="left"
                      items={itemModule}
                      activeKey={activeKeyModule}
                      onChange={(key) => setActiveKeyModule(key)}
                    />
                  </Col>
                  {data?.length > 0 ? (
                    <Col className="col-span-3 py-2.5 px-4">
                      <Checkbox
                        onChange={handleChangeAllModule}
                        checked={isCheckAllModule}
                      >
                        Cho phép tất cả quyền
                      </Checkbox>
                      <Tabs
                        items={itemEpic}
                        activeKey={activeKeyEpic}
                        onChange={(key) => setActiveKeyEpic(key)}
                      />
                      <Checkbox
                        onChange={handleChangeAllEpic}
                        checked={isCheckAllEpic}
                      >
                        Tất cả
                      </Checkbox>
                      <Row className="pl-10 grid-cols-4 mt-2.5">
                        {permissions?.[activeKeyEpic]?.controls?.map((item) => {
                          const newItemsSet = new Set(listPermission);
                          const checked = newItemsSet.has(item?.id);

                          return (
                            <Checkbox
                              value={item?.id}
                              checked={checked}
                              onChange={handleChangeCheck}
                            >
                              {item?.title}
                            </Checkbox>
                          );
                        })}
                      </Row>
                    </Col>
                  ) : (
                    <Col className="col-span-3 py-2.5 px-4">
                      <Spin wrapperClassName="flex justify-center items-center" />
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </FormTera>
        </div>
      </Spin>
    </div>
  );
}

export default PermissionForm;
